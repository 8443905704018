import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs'
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RequiredDocumentsService {
  private serviceAPILink: string = environment.ServiceAPILink;
  private API_URL: string = '/requirement-documents';
  constructor(private http: HttpClient) { }

  getDocumentsRequired () {
    return this.http.get(this.serviceAPILink + this.API_URL + '?_limit=-1')
    .pipe(
      catchError((error) => {
        return observableThrowError(error.error);
      })
    );
  }

  public getDocumentRequired (id: string) {
    return this.http.get(this.serviceAPILink + this.API_URL + '/' + id)
    .pipe(
      catchError((error) => {
        return observableThrowError(error.error);
      })
    );
  }

  public getTotal () {
    return this.http.get<number>(this.serviceAPILink + this.API_URL + '/count')
    .pipe(
      catchError((error) => {
        return observableThrowError(error.error);
      })
    );
  }

  public createDocument (data: any) {
    return this.http.post(this.serviceAPILink + this.API_URL, data)
    .pipe(
      catchError((error) => {
        return observableThrowError(error.error);
      })
    );
  }

  public editDocument (id: string, data: any) {
    return this.http.put(this.serviceAPILink + this.API_URL + '/' + id , data)
    .pipe(
      catchError((error) => {
        return observableThrowError(error.error);
      })
    );
  }

  public deleteDocument (id: string) {
    return this.http.delete(this.serviceAPILink + this.API_URL + '/' + id)
    .pipe(
      catchError((error) => {
        return observableThrowError(error.error);
      })
    );
  }
}
