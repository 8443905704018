import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CountryModel } from 'src/app/helpers/country.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  constructor(public httpClient: HttpClient) {}

  private serviceAPILink: string = environment.ServiceAPILink;
  private API_URL: string = '/countries?_limit=-1';

  public getTotal () {
    return this.httpClient.get<number>(this.serviceAPILink + '/countries/count')
    .pipe(
      catchError((error: HttpErrorResponse) => {
        return observableThrowError(error.error);
      })
    );
  }

  public getCountryData(): Observable<CountryModel[]> {
    return this.httpClient
      .get<CountryModel[]>(this.serviceAPILink + this.API_URL)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return observableThrowError(error.error);
        })
      );
  }

  public getCountries(): Observable<CountryModel[]> {
    return this.httpClient
      .get<CountryModel[]>(this.serviceAPILink + '/countries?_limit=10')
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return observableThrowError(error.error);
        })
      );
  }

  public getCountry(idCountry: string): Observable<CountryModel> {
    return this.httpClient
      .get<CountryModel>(this.serviceAPILink + '/countries/'+ idCountry)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return observableThrowError(error.error);
        })
      );
  }

  public getCountryDataWithDocuments(): Observable<string[]> {
    return this.httpClient
      .get<string[]>(this.serviceAPILink + this.API_URL + '&contentDocuments=true')
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return observableThrowError(error.error);
        })
      );
  }

  getRegiosData(idCountry: number): Observable<string[]> {
    return this.httpClient
      .get<string[]>(this.serviceAPILink + '/regions?country.id='+ idCountry)
      .pipe(
        catchError((error) => {
          return observableThrowError(error.error);
        })
      );
  }

  public createCountry(formData: FormData): Observable<Object> {
    return this.httpClient
      .post(this.serviceAPILink + '/countries', formData)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return observableThrowError(error.error);
        })
      );
  }

  public updateCountry(id: any, formData: FormData): Observable<Object> {
    return this.httpClient
      .put(this.serviceAPILink + '/countries/' + id, formData)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return observableThrowError(error.error);
        })
      );
  }

  public deleteCountry(id: any): Observable<Object> {
    return this.httpClient
      .delete(this.serviceAPILink + '/countries/' + id)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return observableThrowError(error.error);
        })
      );
  }

  public changePage ({start, limit}: any) {
    return this.httpClient
      .get<Array<any>>(this.serviceAPILink + `/countries?_start=${start}&_limit=${limit}`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return observableThrowError(error.error);
        })
      );
  }

  public createRegion(data: any): Observable<Object> {
    return this.httpClient
      .post(this.serviceAPILink + '/regions', data)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return observableThrowError(error.error);
        })
      );
  }
}
