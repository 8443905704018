import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DocumentSoldDetailsModel } from 'src/app/helpers/document-sold-details.model';
import { DocumentSoldModel } from 'src/app/helpers/document-sold.model';
import { OrderDocumentDetail } from 'src/app/helpers/order-document-details.model';
import { orderRequestModel } from 'src/app/helpers/order-request.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(public httpClient: HttpClient) {}

  public serviceAPILink: string = environment.ServiceAPILink;
  public API_URL: string = '/orders';

  public getTotal () {
    return this.httpClient.get<number>(this.serviceAPILink + '/get-documents/count')
    .pipe(
      catchError((error) => {
        return observableThrowError(error.error);
      })
    );
  }

  public getTotalByFilters (idCountry: any, idRegion: any) {
    let query = ''
    if (idCountry && idRegion) query = `country.id=${idCountry}&region.id=${idRegion}`
    else if (idCountry) query = `country.id=${idCountry}`
    else return this.getTotal()
    return this.httpClient.get<number>(this.serviceAPILink + `/get-documents/count?${query}`)
    .pipe(
      catchError((error) => {
        return observableThrowError(error.error);
      })
    );
  }

  public getOrderDocumentdetail(): Observable<OrderDocumentDetail[]> {
    return this.httpClient
      .get<OrderDocumentDetail[]>(this.serviceAPILink + this.API_URL)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return observableThrowError(error.error);
        })
      );
  }

  public getOrderRequestDocumentdetail(): Observable<orderRequestModel[]> {
    return this.httpClient
      .get<orderRequestModel[]>(this.serviceAPILink + '/document-details')
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return observableThrowError(error.error);
        })
      );
  }

  getOrderDocument (limit: any) {
    return this.httpClient.get(this.serviceAPILink + `/get-documents?_limit=${limit}&_sort=created_at:DESC`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return observableThrowError(error.error);
        })
      )
  }

  getOrder (id: string) {
    return this.httpClient.get(this.serviceAPILink + '/get-documents/' + id)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return observableThrowError(error.error)
        })
      )
  }

  getOrderDocumentByCountryAndRegion (country: number = 0, region: number = 0) {
    let query = '';

    if (country > 0) query = 'country.id='+country
    if (region > 0 && country > 0) query += query + '&region.id='+region
    else if (region > 0) query = 'region.id='+region

    return this.httpClient.get(this.serviceAPILink + `/get-documents?${query}&_limit=100&_sort=created_at:DESC`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return observableThrowError(error.error);
        })
      )
  }

  public changePage ({start, limit}: any) {
    return this.httpClient
      .get<Array<any>>(this.serviceAPILink + `/get-documents?_start=${start}&_limit=${limit}&_sort=created_at:DESC`)
      .pipe(
        catchError((error) => {
          return observableThrowError(error.error);
        })
      );
  }

  public changePageByRegion (idRegion: any, idCountry: any, {start, limit}: any) {
    let query = ''
    if (idCountry > 0) query = 'country.id='+ idCountry
    if (idRegion > 0 &&  idCountry > 0) query += query + '&region.id='+ idRegion
    else if (idRegion > 0) query = 'region.id='+ idRegion
    return this.httpClient
      .get<Array<any>>(this.serviceAPILink + `/get-documents?${query}&_start=${start}&_limit=${limit}&_sort=created_at:DESC`)
      .pipe(
        catchError((error) => {
          return observableThrowError(error.error);
        })
      );
  }
}
