import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable, throwError as observableThrowError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PositionsService {
  public serviceAPILink: string = environment.ServiceAPILink;
  public API_URL: string = '/positions';

  constructor(public httpClient: HttpClient) { }


  getPositions () {
    return this.httpClient
      .get(this.serviceAPILink + this.API_URL)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return observableThrowError(error.error);
        })
      );
  }
}
